<template>
   <div class="rating">
      <div @click="setRating" class="rating__inner">
         <div :style="{ width: calculatedRating }" class="rating__active"></div>

         <fieldset class="rating__group">
            <legend class="rating__title">Рейтинг товара</legend>

            <label class="rating__star">
               <input class="rating__input" type="radio" name="rating" />
            </label>
            <label class="rating__star">
               <input class="rating__input" type="radio" name="rating" />
            </label>
            <label class="rating__star">
               <input class="rating__input" type="radio" name="rating" />
            </label>
            <label class="rating__star">
               <input class="rating__input" type="radio" name="rating" />
            </label>
            <label class="rating__star">
               <input class="rating__input" type="radio" name="rating" />
            </label>
         </fieldset>
      </div>

      <p class="rating__value">{{ currentRating }}</p>
   </div>
</template>

<script>
   export default {
      name: 'ProductRating',

      props: {
         rating: {
            type: Number,
            required: true,
            default: 0
         }
      },

      data() {
         return {
            currentRating: 0
         };
      },

      methods: {
         setRating({ currentTarget: target, clientX }) {
            const cursorX = parseInt(`${clientX - target.getBoundingClientRect().left}`);
            const width = parseInt(getComputedStyle(target).width);

            this.currentRating = ((cursorX / width) * 5).toFixed(2).replace('.00', '');
         }
      },

      computed: {
         calculatedRating() {
            return `${this.currentRating / 0.05}%`;
         }
      },

      watch: {
         rating: {
            handler(value) {
               this.currentRating = value;
            },
            immediate: true
         }
      }
   };
</script>
