<template>
   <div class="product-description-tab">
      <h3 class="product-description-tab__title">{{ product.name }}</h3>
      <p class="product-description-tab__content">{{ product.description }}</p>
   </div>
</template>

<script>
   export default {
      name: 'TabDescription',

      props: {
         product: { type: Object, required: true }
      }
   };
</script>
