<template>
   <div class="product-param">
      <h3 class="product-param__title">{{ title }}:</h3>

      <div class="product-param__inner">
         <button
            :data-parameter="param.type"
            :style="{ '--product-color': param.selectedProp.value }"
            @click="showSelectedParam(param)"
            class="product-param__dropdown-header dropdown-header btn"
         >
            {{ param.selectedProp.name }}
         </button>

         <ul
            :data-parameter="param.type"
            :class="{ 'displayed-filter': typeOfSelectedParam === param.type }"
            class="product-param__dropdown-body dropdown-body"
         >
            <li v-for="(prop, idx) in param.props" :key="idx" class="dropdown-body__item">
               <button
                  :style="{ '--product-color': prop.value }"
                  @click="setSelectedParam(param, prop)"
                  class="dropdown-body__btn btn"
               >
                  {{ prop.name }}
               </button>
            </li>
         </ul>
      </div>
   </div>
</template>

<script>
   export default {
      name: 'ProductParam',

      props: {
         title: { type: String },
         type: { type: String, required: true },
         productParam: {
            type: Array,
            required: true,
            default() {
               return [];
            }
         }
      },

      data() {
         return {
            param: {
               type: '',
               props: [],
               selectedProp: {}
            },
            typeOfSelectedParam: ''
         };
      },

      mounted() {
         document.addEventListener('click', this.closeDropdownList);
      },

      beforeUnmount() {
         document.removeEventListener('click', this.closeDropdownList);
      },

      methods: {
         showSelectedParam(param) {
            if (param.props.length) {
               this.typeOfSelectedParam = this.typeOfSelectedParam === param.type ? '' : param.type;
            }
         },

         closeDropdownList(e) {
            if (e.target.dataset.parameter !== this.typeOfSelectedParam) {
               this.typeOfSelectedParam = '';
            }
         },

         fillParamWithData(values) {
            this.param.props = [...values];
            this.param.selectedProp = {};

            this.setSelectedParam(this.param, this.param.props[0]);
         },

         setSelectedParam(param, prop) {
            if (Object.keys(param.selectedProp).length) {
               param.props.push(param.selectedProp);
            }

            param.selectedProp = prop;
            param.props.splice(param.props.indexOf(prop), 1);
            param.props.sort((a, b) => (a.name < b.name ? -1 : 1));
         }
      },

      watch: {
         type: {
            handler(value) {
               this.param.type = value;
            },
            immediate: true
         },

         productParam: {
            handler(value) {
               if (value.length) {
                  this.fillParamWithData(value);
               }
            },
            immediate: true
         }
      }
   };
</script>
