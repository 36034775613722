<template>
   <section class="product-preview main__product-preview">
      <div class="product-preview__container container">
         <product-slider :data="product.images" class="product-preview__product-slider" />

         <div class="description">
            <h2 class="description__title">{{ product.name }}</h2>

            <div class="description__wrap">
               <span class="description__vendor-code">Артикул: {{ product.vendorCode }}</span>

               <span
                  :class="{ 'description__availability--active': product.availability }"
                  class="description__availability"
               >
                  Есть в наличии
               </span>
            </div>

            <product-rating :rating="product.rating" class="description__rating" />

            <div class="description__wrapper">
               <product-param
                  v-for="param in parameters"
                  :key="param.type"
                  :title="param.title"
                  :type="param.type"
                  :product-param="product[param.type]"
               />
            </div>

            <div class="description__product-prices product-prices">
               <span class="product-prices__old">{{ product.oldPrice }}.00</span>
               <span class="product-prices__new">{{ product.price }}.00</span>
            </div>

            <div class="description__inner">
               <button @click="addProductToCart" class="description__buy-btn btn">В корзину</button>

               <button @click="addProductToFavorites" class="favorites-btn btn">
                  <svg-icon-template
                     :icon-name="'subscribe'"
                     class="favorites-btn__img"
                     alt="Добавить в избранное"
                  />

                  <span>В избранное</span>
               </button>
            </div>

            <social-networks class="description__social-networks" />
         </div>
      </div>
   </section>
</template>

<script>
   import ProductSlider from './modules/ProductSlider';
   import ProductRating from './modules/ProductRating';
   import ProductParam from './modules/ProductParam';
   import SvgIconTemplate from '@/components/templates/SvgIconTemplate';
   import SocialNetworks from '@/components/templates/SocialNetworks';
   import { mapActions } from 'vuex';

   export default {
      name: 'ProductPreview',

      components: {
         ProductSlider,
         ProductRating,
         ProductParam,
         SvgIconTemplate,
         SocialNetworks
      },

      props: {
         product: { type: Object, required: true }
      },

      inject: ['openModal'],

      data() {
         return {
            item: {},
            parameters: [
               {
                  type: 'sizes',
                  title: 'Выберите размер'
               },
               {
                  type: 'colors',
                  title: 'Выберите цвет'
               }
            ]
         };
      },

      methods: {
         ...mapActions('cart', ['saveProductState']),
         ...mapActions('favorites', { saveStateOfFavoriteProduct: 'saveProductState' }),

         addProductToCart() {
            if (this.product.availability) {
               this.saveProductState({ product: this.item, isRemove: false });

               this.openModal({ name: 'ModalCart', isActive: true });
            } else {
               this.openModal({
                  name: 'ModalNotification',
                  iconName: 'warning',
                  message: 'Извините, товар отсутствует!',
                  isActive: true
               });
            }
         },

         addProductToFavorites() {
            this.saveStateOfFavoriteProduct({ product: this.item, isRemove: false });

            this.openModal({ name: 'ModalFavorites', isActive: true });
         }
      },

      computed: {
         imageDescription() {
            return `Товар ${this.product.name}`;
         },

         imagePath() {
            return require(`@/assets/images/products/${this.product.images.folder}/${this.product.preview}`);
         }
      },

      watch: {
         product: {
            handler(value) {
               if (value.id) {
                  this.item = value;
                  this.item.image = this.imagePath;
                  this.item.imageDescription = this.imageDescription;
               }
            },
            immediate: true
         }
      }
   };
</script>
