<template>
   <article class="product-slider">
      <h2 class="product-slider__title">Галерея</h2>

      <div class="product-slider__top-slider">
         <swiper :navigation="navigation" :thumbs="{ swiper: thumbsSwiper }" class="upper-gallery">
            <swiper-slide v-for="(item, idx) in slides" :key="idx" class="upper-gallery__slide">
               <img :src="item" class="upper-gallery__img" alt="Изображение товара" />
            </swiper-slide>
         </swiper>

         <button
            class="product-slider__prev-btn prev-btn prev btn"
            aria-label="Перейти к предыдущему слайду"
         ></button>

         <button
            class="product-slider__next-btn next-btn next btn"
            aria-label="Перейти к следующему слайду"
         ></button>
      </div>

      <div class="product-slider__bottom-slider">
         <swiper
            :spaceBetween="5"
            :slidesPerView="4"
            :freeMode="true"
            :watchSlidesVisibility="true"
            :watchSlidesProgress="true"
            :navigation="navigation"
            @swiper="setThumbsSwiper"
            class="gallery-thumbs"
         >
            <swiper-slide v-for="(item, idx) in slides" :key="idx" class="gallery-thumbs__slide">
               <img :src="item" class="gallery-thumbs__img" alt="Изображение товара" />
            </swiper-slide>
         </swiper>

         <button
            class="product-slider__swiper-button-prev prev btn"
            aria-label="Перейти к предыдущему слайду"
         ></button>

         <button
            class="product-slider__swiper-button-next next btn"
            aria-label="Перейти к следующему слайду"
         ></button>
      </div>
   </article>
</template>

<script>
   import { Swiper, SwiperSlide } from 'swiper/vue';
   import SwiperCore, { Navigation, Thumbs } from 'swiper/core';

   SwiperCore.use([Navigation, Thumbs]);

   export default {
      name: 'ProductSlider',

      components: {
         Swiper,
         SwiperSlide
      },

      props: {
         data: {
            type: Object,
            required: true,
            default() {
               return { names: [] };
            }
         }
      },

      data() {
         return {
            thumbsSwiper: null,
            navigation: {
               nextEl: '.next',
               prevEl: '.prev'
            }
         };
      },

      methods: {
         setThumbsSwiper(swiper) {
            this.thumbsSwiper = swiper;
         }
      },

      computed: {
         slides() {
            return this.data.names.map((name) =>
               require(`@/assets/images/products/${this.data.folder}/${name}`)
            );
         }
      }
   };
</script>
