<template>
   <article v-show="products.length" class="other-products main__other-products">
      <div ref="container" class="container">
         <h2 class="other-products__section-title section-title">Похожие товары</h2>

         <div class="other-products__wrap">
            <swiper
               :loopFillGroupWithBlank="true"
               :navigation="navigation"
               :breakpoints="breakpoints"
               class="other-products__cards cards"
            >
               <swiper-slide
                  v-for="product in products"
                  :key="product.id"
                  class="cards__item cards__item--other"
               >
                  <article
                     :class="{
                        'card--sale': product.sections.includes('sale'),
                        'card--new': product.sections.includes('new-products')
                     }"
                     class="card"
                  >
                     <div class="card__wrap card__wrap--other">
                        <img
                           :src="product.image"
                           :alt="product.imageDescription"
                           class="card__img card__img--other"
                        />

                        <h3 class="card__title card__title--other swiper-no-swiping">
                           <router-link :to="{ name: 'Product', params: { id: product.id } }">
                              {{ product.name }}
                           </router-link>
                        </h3>

                        <p class="card__product-price swiper-no-swiping">
                           {{ currency }}&nbsp;{{ product.price }}.00
                        </p>
                     </div>
                  </article>
               </swiper-slide>
            </swiper>

            <button
               v-show="isDisplayed"
               class="swiper-button-prev prev-btn btn"
               aria-label="Перейти к предыдущему слайду"
            ></button>

            <button
               v-show="isDisplayed"
               class="swiper-button-next next-btn btn"
               aria-label="Перейти к следующему слайду"
            ></button>
         </div>
      </div>
   </article>
</template>

<script>
   import { Swiper, SwiperSlide } from 'swiper/vue';
   import SwiperCore, { Navigation } from 'swiper/core';
   import { mapState, mapActions } from 'vuex';

   SwiperCore.use([Navigation]);

   export default {
      name: 'OtherProducts',

      components: {
         Swiper,
         SwiperSlide
      },

      props: {
         category: { type: String, required: true, default: '' }
      },

      data() {
         return {
            breakpoints: {
               320: {
                  slidesPerView: 1
               },
               451: {
                  slidesPerView: 1
               },
               577: {
                  slidesPerView: 2
               },
               769: {
                  slidesPerView: 3
               },
               993: {
                  slidesPerView: 4
               },
               1201: {
                  slidesPerView: 5
               }
            },
            navigation: {
               nextEl: '.swiper-button-next',
               prevEl: '.swiper-button-prev'
            },
            isDisplayed: true
         };
      },

      mounted() {
         const ro = new ResizeObserver((entries) => {
            for (let entry of entries) {
               const width = entry.contentBoxSize[0].inlineSize;
               const breakpoints = Object.entries(this.breakpoints);

               const currentBreakpoint = breakpoints.find((item) => width < +item[0]);

               this.isDisplayed =
                  currentBreakpoint && currentBreakpoint[1].slidesPerView < this.products.length;
            }
         });

         ro.observe(this.$refs.container);
      },

      methods: {
         ...mapActions('product', ['fetchSimilarProducts'])
      },

      computed: {
         ...mapState('product', ['similarProducts']),

         products() {
            return this.similarProducts.filter((item) => item.id !== +this.$route.params.id);
         }
      },

      watch: {
         category: {
            handler(value) {
               value && this.fetchSimilarProducts({ section: 'category', value }); //if
            },
            immediate: true
         }
      }
   };
</script>
