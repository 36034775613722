<template>
   <div class="product-parameters-tab">
      <table class="product-parameters-tab__table">
         <caption class="product-parameters-tab__title">
            Характеристики
         </caption>

         <tbody>
            <tr
               v-for="(parameter, idx) in product.parameters"
               :key="idx"
               class="product-parameters-tab__row"
            >
               <template v-for="(item, paramIdx) in parameter" :key="paramIdx">
                  <td class="product-parameters-tab__property">{{ item.name }}</td>
                  <td class="product-parameters-tab__value">{{ item.description }}</td>
               </template>
            </tr>
         </tbody>
      </table>
   </div>
</template>

<script>
   export default {
      name: 'TabParameters',

      props: {
         product: { type: Object, required: true }
      }
   };
</script>
