<template>
   <div class="product-delivery-tab">
      <p class="product-delivery-tab__content">В разработке!</p>
   </div>
</template>

<script>
   export default {
      name: 'TabDelivery',

      props: {
         product: { type: Object, required: true }
      }
   };
</script>
