<template>
   <section class="product-info main__product-info">
      <div class="container">
         <h2 class="product-info__title">Особенности продукта</h2>

         <div class="product-info__wrap">
            <ul class="tabs">
               <li v-for="tab in tabs" :key="tab.name" class="tabs__item">
                  <button
                     :class="{ 'tabs__btn--active': tab.isActive }"
                     @click="selectTab(tab)"
                     class="tabs__btn btn"
                  >
                     {{ tab.name }}
                  </button>
               </li>
            </ul>
         </div>

         <component :is="selectedTabComponent" :product="product" />
      </div>
   </section>
</template>

<script>
   import TabDescription from './Tabs/TabDescription';
   import TabParameters from './Tabs/TabParameters';
   import TabPayment from './Tabs/TabPayment';
   import TabDelivery from './Tabs/TabDelivery';
   import TabReviews from './Tabs/TabReviews';

   export default {
      name: 'ProductFeatures',

      components: {
         TabDescription,
         TabParameters,
         TabPayment,
         TabDelivery,
         TabReviews
      },

      props: {
         product: { type: Object, required: true }
      },

      data() {
         return {
            tabs: [
               {
                  name: 'Описание',
                  component: 'TabDescription',
                  isActive: false
               },
               {
                  name: 'Характеристики',
                  component: 'TabParameters',
                  isActive: true
               },
               {
                  name: 'Оплата',
                  component: 'TabPayment',
                  isActive: false
               },
               {
                  name: 'Доставка',
                  component: 'TabDelivery',
                  isActive: false
               },
               {
                  name: 'Отзывы',
                  component: 'TabReviews',
                  isActive: false
               }
            ],
            selectedTabComponent: 'TabParameters'
         };
      },

      methods: {
         selectTab(tab) {
            this.tabs.forEach((item) => {
               item.isActive = item.name === tab.name;
            });

            this.selectedTabComponent = tab.component;
         }
      }
   };
</script>
