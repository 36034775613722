<template>
   <div class="product-reviews-tab">
      <p class="product-reviews-tab__content">Отзывов ещё нет!</p>
   </div>
</template>

<script>
   export default {
      name: 'TabReviews',

      props: {
         product: { type: Object, required: true }
      }
   };
</script>
