<template>
   <main class="main">
      <template v-if="product.id">
         <app-breadcrumbs :routes="breadcrumbs" :last-element="product.name" />
         <product-preview :product="product" />
         <product-features :product="product" />
         <other-products :category="productCategory" />
      </template>

      <app-notifications v-if="!product.id" notification-type="Loading" />
   </main>
</template>

<script>
   import AppBreadcrumbs from '@/components/templates/AppBreadcrumbs';
   import ProductPreview from './modules/Preview/ProductPreview';
   import ProductFeatures from './modules/ProductFeatures/ProductFeatures';
   import OtherProducts from './modules/OtherProducts/OtherProducts';
   import AppNotifications from '@/components/templates/Notifications/AppNotifications';
   import { mapState, mapActions } from 'vuex';

   export default {
      name: 'ProductPage',

      components: {
         AppBreadcrumbs,
         ProductPreview,
         ProductFeatures,
         OtherProducts,
         AppNotifications
      },

      props: {
         id: { type: String, required: true }
      },

      inject: ['backToTopOfPage'],

      methods: {
         ...mapActions('product', ['fetchProduct'])
      },

      computed: {
         ...mapState('product', ['product', 'errorMessage']),

         productCategory() {
            return this.product.category[0].name;
         },

         breadcrumbs() {
            return [
               {
                  name: 'Catalog',
                  query: { page: 1 },
                  title: 'Каталог товаров'
               },
               {
                  name: 'Catalog',
                  query: { filter: this.productCategory, page: 1 },
                  title: this.productCategory
               }
            ];
         }
      },

      watch: {
         errorMessage(error) {
            error?.status === 404 && this.$router.replace({ name: 'NotFound' }); //if
         },

         id: {
            handler(value) {
               value && this.fetchProduct(value); //if

               this.backToTopOfPage('smooth');
            },
            immediate: true
         }
      }
   };
</script>
